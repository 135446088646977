import { Box, Breadcrumbs, Button, Card, CardContent, Link} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Layout } from "../../components/chrome/Layout";
import usePageTitle from "../../hooks/pageTitle";
import { useHistory } from "react-router-dom";
import { useGetAssessmentUsageQuery } from "../../rtkquery/apis/assessmentManagementApi";
import FlexGrid from "../../components/shared/flexGrid";
import { Stack } from "react-bootstrap";
import { UserServices } from "../../services/userService";
import { Error } from "../Error";
import React from "react";

export const AssessmentsListPage: React.FC = () => {
	
	const history = useHistory();
	const {data: assessmentUsageData} = useGetAssessmentUsageQuery();

	const userService = new UserServices();
	
	const getTitle = () => {
		return "Assessments | Center for Creative Leadership";
	}
	usePageTitle(getTitle());

	const openBtnClicked = (row: any) => {
		history.push("/assessment-management/" + row.instrumentKey.toString());
	}

	const columns: GridColDef[] = [
		{ field: 'instrumentKey', headerName: 'InstrumentKey', width: 120 },
		{ field: 'instrumentName', headerName: 'Name', flex: 1 },
		{ field: 'surveyPlatform', headerName: 'Platform', width: 120 },
		{ field: 'self', headerName: 'Self', width: 90, type: 'boolean' },
		{ field: 'rater', headerName: 'Rater', width: 90, type: 'boolean' },
		{ field: 'active', headerName: 'Active', width: 90, type: 'boolean' },
		{
			field: "openBtn",
			headerName: " ",
			width: 100,
			renderCell: (params: GridRenderCellParams) => (
				<Button size={"small"} variant={"outlined"} onClick={() => openBtnClicked(params.row)}>
					Open
				</Button>
			)
		}
	];
	const containerRef = React.useRef<HTMLDivElement | null>(null);
	const minHeight = 400;
	const maxHeight = 800;

	if (!userService.isAssessmentManager()) {
		return ( <Error /> );
	}
	
	
	return (
		<Layout>
			<Card > 
				<CardContent>
				<Stack>
					<Box sx={{paddingBottom: 5}}>
					    <Breadcrumbs aria-label="breadcrumb">
							
                            <Link
                            underline="hover"
                            color="text.primary"
                            href="/assessment-management"
                            aria-current="page"
                            >
                            Assessment Management
                            </Link>
                        </Breadcrumbs>
					</Box>
					<FlexGrid>
						<div
						style={{
							height: '800px',
							width: '100%',
							overflow: 'auto',
						}}
						>
							<DataGrid rows={assessmentUsageData} columns={columns} ></DataGrid>
						</div>
					</FlexGrid>
					</Stack>
				</CardContent>

			</Card>
			
			
		</Layout>
	);
};
