import { Box, Stack, Table, TableBody, TableCell, TableRow  } from "@mui/material";
import { PieChart } from "@mui/x-charts"; // Add this import
import React from "react";

export const AssessmentSummary: React.FC = () => {
	
	const [value, setValue] = React.useState(0);

	return (
         
        <Stack direction={"row"} spacing={2} height={1}>
            <Box width={1}>
            <Table>
                <TableBody>
                <TableRow>
                        <TableCell width={70} align="right">FormMasterKey:</TableCell>
                        <TableCell>1028</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">Platform:</TableCell>
                        <TableCell>AP2</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">Languages:</TableCell>
                        <TableCell>English, Spanish, French</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </Box>

            <PieChart
                    series={[
                        {
                        data: [
                            { id: 0, value: 700, label: 'Active Participants' },
                            { id: 1, value: 90, label: 'Invited Participants' },
                            { id: 2, value: 2034, label: 'Active Raters' },
                            { id: 3, value: 120, label: 'Invited Raters' },
                        ],
                        },
                    ]}
                    height={400}
                    width={800}
                />
        </Stack>       
					
	);
};
