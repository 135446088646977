import React, { Fragment, useEffect, useRef, useState } from 'react';
import { PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, Inject } from '@syncfusion/ej2-react-pdfviewer';
import "./ProfilePage.scss";
import { Grid2 } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/User/UserSlice';
import { getAsset, getDocument } from '../services/apiService';
import { setShowToast } from '../store/Navigation/NavSlice';
import { Layout } from '../components/chrome/Layout';
import { Loading } from '../components';
import { RootState } from '../store';
import { useSearchParams } from 'react-router-dom-v5-compat';
import '../index.scss';

const envSettings = window as any;

const ReportViewer: React.FC = () => {
    const dispatch = useDispatch();
    const [search] = useSearchParams();
    const { loading } = useSelector(
		(state: RootState) => state.user
	);
    const viewerRef = useRef<PdfViewerComponent | null>(null);
    const [reportUrl, setReportUrl] = useState("");
    const [apiServiceUrl, setApiServiceUrl] = useState("");
    const [documentName, setDocumentName] = useState("");

    useEffect(() => {
        let id = search.get("id");
		let pid = search.get("pid"); // for asset this will assetInfoId
		let docId = search.get("did"); // for asset this will version number
		let docName = search.get("dName"); // for asset this will be null
        let reportType = search.get("type");
        setDocumentName(docName ?? "test");
        if (id && pid && docId) {
            let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/participant-landing/documents/${pid}/${docId}/${id}`;
            setApiServiceUrl(url);
            if (reportType === "asset") {
                viewAsset(parseInt(id), parseInt(pid), docId);
            }
            else {
                viewDocument(parseInt(id), parseInt(pid), parseInt(docId));
            }
        }
        window.scrollTo(0, 0);
    },[])

    useEffect(() => {
        if (reportUrl) {
            loadPdfFromUrl();
        }
    },[reportUrl, documentName, apiServiceUrl])

    const loadPdfFromUrl = async () => {
        try {
            // Fetch the blob file
            const response = await fetch(reportUrl);
            if (!response.ok) {
                throw new Error(`Error fetching PDF: ${response.statusText}`);
            }

            const blob = await response.blob();  // Get the blob from the response

            // Create a blob URL from the blob object
            const blobUrl = URL.createObjectURL(blob);

            setTimeout(() => {
                if (viewerRef.current) {
                    // Load the blob URL into the PDF viewer
                    viewerRef.current.load(blobUrl, documentName);  // Passing the blob URL and document name
                    dispatch(setLoading(false));
                } else {
                console.error("viewerRef is not initialized");
                }
            }, 500);
        } catch (error) {
            console.error('Failed to load PDF', error);
        }
    }

    const viewDocument = (
		individualId: number,
		participantId: number,
		documentId: number,
	) => {
		dispatch(setLoading(true));
		if (participantId && documentId && individualId) {
			getDocument(participantId, documentId, individualId)
				.then(async (res) => {
					// Creating an object for the PDF
					var data = new Blob([res], { type: "application/pdf" });
					var pdfUrl = window.URL.createObjectURL(data);
                    setReportUrl(pdfUrl);
				})
				.catch((e) => {
					dispatch(setLoading(false));
					console.log("File Not Found");
				});
		}
	};

    const viewAsset = (
		individualId: number,
		assetInfoId: number,
		version: string
	) => {
		dispatch(setLoading(true));
		if (individualId && assetInfoId && version) {
			getAsset(individualId, assetInfoId, version)
				.then((res) => {
					// Creating an object for the PDF
					var data = new Blob([res], { type: "application/pdf" });
					var pdfUrl = window.URL.createObjectURL(data);
                    setReportUrl(pdfUrl);
				})
				.catch((e) => {
					dispatch(setLoading(false));
					console.log("File Not Found");
				});
		}
	};


    return (
        <Layout>
            {loading && <Loading />}
            <Grid2 container spacing={0} justifyContent={"center"}>
                {(apiServiceUrl) &&
                <PdfViewerComponent
                    resourceUrl="https://cdn.syncfusion.com/ej2/26.2.11/dist/ej2-pdfviewer-lib"
                    style={{ width: '100%', height: '680px' }}
                    ref={viewerRef}
                >
                    <Inject services={[Toolbar, Navigation, Magnification, TextSearch]} />
                </PdfViewerComponent>}
            </Grid2>
        </Layout>
    );
};

export default ReportViewer;