import { Box, Breadcrumbs, Card, CardContent, Link, Stack } from "@mui/material";
import { Layout } from "../../components/chrome/Layout";
import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import FlexGrid from "../../components/shared/flexGrid";
import { assessmentManagementApi, useGetAssessmentFormLanguagesQuery, useGetAssessmentUsageQuery } from "../../rtkquery/apis/assessmentManagementApi";
import { UserServices } from "../../services/userService";
import { Error } from "../Error";

export const FormItemListPage: React.FC = () => {
	
    const id = () => {
		var pathName = window.location.pathname;
		var pathArray = pathName.split('/');
		var id = pathArray[3];
		return parseInt(id);
	}

  const parentId = () => {
    var pathName = window.location.pathname;
    var pathArray = pathName.split('/');
    var id = pathArray[2];
    return parseInt(id);
  }

  const userService = new UserServices();
  const { data: assessmentFormItems } = assessmentManagementApi.useGetAssessmentFormItemsQuery(id());	
  const { data: assessmentLanguageData } = useGetAssessmentFormLanguagesQuery(parentId());
  const { data: assessmentUsageData} = useGetAssessmentUsageQuery();
  const selectedAssessmentUsage = assessmentUsageData?.find(assessment => assessment.instrumentKey === parentId());
  const selectedAssessmentLanguage = assessmentLanguageData?.find(assessment => assessment.formMasterKey === id());

	const columns: GridColDef[] = [
		{ field: 'formItemKey', headerName: 'FormItemKey', width: 120,  minWidth:120 },
		{ field: 'formItemText', headerName: 'Name', flex: 1 },
        { field: 'formItemTypeKey', headerName: 'Type', width: 200, minWidth:200, type: 'number' },
		{ field: 'formItemSeqNum', headerName: 'Seq Num', width: 200, minWidth:200, type: 'number' },
        { field: 'formItemNum', headerName: 'Item Num', width: 200, minWidth:200, type: 'number' }
	];


  function getRowId(row) {
      return row.formItemKey;
    }

  const parentPath = () => {
    return "/assessment-management/" + parentId().toString();
  }

  const path = () => {   
    return "/assessment-management/" + parentId().toString() + "/" + id().toString();
  }

  if (!userService.isAssessmentManager()) {
    return ( <Error /> );
  }

	return (
		<Layout>
            <Card>
				
				<CardContent>
                <Stack>
					<Box sx={{paddingBottom: 5}}>
					    <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/assessment-management">
                            Assessment Management
                            </Link>
                            <Link
                            underline="hover"
                            color="inherit"
                            href={parentPath()} 
                            >
                            {selectedAssessmentUsage?.instrumentName}
                            </Link>
                            <Link
                            underline="hover"
                            color="text.primary"
                            href={path()}
                            aria-current="page"
                            >
                            {selectedAssessmentLanguage?.formMasterName}
                            </Link>
                        </Breadcrumbs>
					</Box>
                    <FlexGrid>
                        <div
                          style={{
                            height: '800px',
                            width: '100%',
                            overflow: 'auto',
                          }}
                          >
                          <DataGrid getRowId={getRowId} rows={assessmentFormItems} columns={columns} />
                        </div>
                    </FlexGrid>
                    </Stack>  
                </CardContent>
            </Card>
        </Layout>	
        		
	);
};
